<template>
  <div class="bg-gray-200 p-4">
    <div class="mx-auto sm:max-w-xl m-4 px-4">
      <div v-if="!user">
        <h3 class="text-center">Pro Member Sign Up</h3>
        <div class="flex flex-col sm:flex-row mt-4 sm:mt-20">
          <button
            v-facebook-login-button="facebookId"
            class="facebook-login-button shadow sm:w-1/2 sm:mr-1 text-lg rounded h-16 sm:h-14 flex justify-around items-center"
          >
            <font-awesome-icon class="text-5xl sm:text-4xl" :icon="['fab', 'facebook-square']" />
            Sign up with Facebook
          </button>
          <button
            v-google-signin-button="clientId"
            class="google-login-button shadow sm:w-1/2 my-1 mt-2 sm:my-0 sm:ml-1 text-lg rounded h-16 sm:h-14 flex justify-around items-center"
          >
            <img src="images/icons/google-icon.svg" class="w-12" alt="Google Icon" />
            Sign up with Google
          </button>
        </div>
        <div class="my-1 flex items-center justify-center">
          <hr class="hidden md:block border-t border-gray-600 w-5/12" />
          <div class="mx-2 text-gray-600">or</div>
          <hr class="hidden md:block border-t border-gray-600 w-5/12" />
        </div>

        <form @submit.prevent="register" autocomplete="on">
          <fieldset class="space-y-2">
            <div class="flex flex-col">
              <label
                for="name"
                class="text-primary-dark font-semibold text-sm"
                style="margin-bottom: 0"
              >
                Name
              </label>
              <input
                type="text"
                autocomplete="name"
                id="name"
                class="focus:border-blue-500 block w-full sm:text-sm border"
                required
                placeholder="John Doe"
                v-model="name"
              />
            </div>
            <div>
              <label for="email" class="text-primary-dark font-semibold text-sm">Email</label>
              <div class="mt-1 relative border">
                <div class="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                  <svg
                    class="h-5 w-5 text-gray-400"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z"
                    />
                    <path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
                  </svg>
                </div>
                <input
                  type="email"
                  name="email"
                  id="email"
                  class="focus:border-blue-500 block w-full sm:text-sm border pl-10"
                  placeholder="you@example.com"
                  required
                  v-model="email"
                />
              </div>
            </div>

            <div>
              <label for="phone-number" class="text-primary-dark font-semibold text-sm">
                Phone Number
              </label>
              <div class="focus:border-blue-500 block w-full sm:text-sm border">
                <input
                  type="text"
                  name="phone-number"
                  id="phone-number"
                  class="border focus:border-blue-500 block w-full sm:text-sm"
                  placeholder="+1 (555) 987-6543"
                  required
                  v-model="phone"
                />
              </div>
            </div>
            <div class="flex flex-col">
              <label for="title" class="text-primary-dark font-semibold text-sm">
                Job title <span class="text-sm text-gray-500">(optional)</span>
              </label>
              <input
                type="text"
                id="title"
                class="focus:border-blue-500 block w-full sm:text-sm border"
                placeholder="owner"
                v-model="jobTitle"
              />
            </div>
            <div class="flex flex-col">
              <label
                for="password"
                class="text-primary-dark font-semibold text-sm"
                style="margin-bottom: 0"
              >
                Password
              </label>
              <input
                type="password"
                autocomplete="password"
                id="password"
                class="focus:border-blue-500 block w-full sm:text-sm border"
                placeholder=""
                v-model="password"
                required
              />
            </div>
            <div class="flex flex-col">
              <label
                for="password"
                class="text-primary-dark font-semibold text-sm"
                style="margin-bottom: 0"
              >
                Confirm password
              </label>
              <input
                type="password"
                autocomplete="confirmPassword"
                id="confirmPassword"
                class="focus:border-blue-500 block w-full sm:text-sm border"
                placeholder=""
                v-model="confirmPassword"
                required
              />
            </div>
          </fieldset>

          <div class="border-gray-400 border-t-2 mt-4">
            <h3>Business Information</h3>
            <div class="grid md:grid-cols-2 gap-4">
              <div class="flex flex-col">
                <label for="businessName" class="text-primary-dark font-semibold text-sm">
                  Business name
                </label>
                <input
                  type="text"
                  id="businessName"
                  class="focus:border-blue-500 block w-full sm:text-sm border"
                  placeholder="Page Custom Supply LLC"
                  required
                  v-model="company.name"
                />
              </div>

              <div class="flex flex-col">
                <label for="businessEmail" class="text-primary-dark font-semibold text-sm"
                  >Business email</label
                >
                <input
                  type="text"
                  placeholder="support@pagecustomsupplyllc.com"
                  id="businessEmail"
                  class="focus:border-blue-500 block w-full sm:text-sm border"
                  required
                  v-model="company.email"
                />
              </div>

              <div class="flex flex-col">
                <label for="type" class="text-primary-dark font-semibold text-sm">
                  Business type
                </label>
                <select
                  class="w-full mx-auto md:mx-0 shadow border focus:border-blue-500 block sm:text-sm rounded"
                  id="type"
                  required
                  v-model="company.accountType"
                >
                  <option value="Shop">Cabinet/Furniture Shop</option>
                  <option value="Dealer">Cabinet Dealer</option>
                  <option value="Interior">Interior Designer</option>
                  <option value="Contractor">Contractor</option>
                  <option value="Other">Other</option>
                </select>
              </div>

              <div class="flex flex-col">
                <label for="businessPhone" class="text-primary-dark font-semibold text-sm">
                  Business phone
                </label>
                <input
                  type="text"
                  id="businessPhone"
                  class="focus:border-blue-500 block w-full sm:text-sm border"
                  placeholder="801-555-5555"
                  required
                  v-model="company.phone"
                />
              </div>
              <div class="flex justify-between items-center col-span-2">
                <div class="flex flex-col row-span-2">
                  <label for="taxExempt" class="text-primary-dark font-semibold text-sm">
                    Are you Tax Exempt?
                  </label>
                  <div>
                    <toggle-button
                      id="taxExempt"
                      :color="color"
                      :width="toggleWidth"
                      :value="taxExempt"
                      :labels="{ checked: 'yes', unchecked: 'no' }"
                      :margin="3"
                      @change="changeTaxExempt()"
                    />
                  </div>
                </div>
                <div v-if="taxExempt">
                  <button
                    type="button"
                    class="btn-primary my-3 w-full rounded-full sm:w-1/3 md:w-auto md:rounded"
                    @click="taxExemptForm"
                  >
                    Tax Exemption Form
                  </button>
                </div>
              </div>
              <!-- Business Address -->
              <div class="border-t-2 border-primary md:border-none py-2 col-span-2">
                <h4>Address</h4>

                <div class="flex flex-col w-full">
                  <label
                    for="businessAddr"
                    class="text-primary-dark font-semibold text-sm"
                    style="margin-bottom: 0"
                  >
                    Street
                  </label>
                  <input
                    type="text"
                    id="businessAddr"
                    class="focus:border-blue-500 block w-full sm:text-sm border"
                    required
                    v-model="company.address"
                  />
                </div>

                <div class="flex flex-col my-2">
                  <label
                    for="businessCity"
                    class="text-primary-dark font-semibold text-sm"
                    style="margin-bottom: 0"
                  >
                    City
                  </label>
                  <input
                    type="text"
                    id="businessCity"
                    class="focus:border-blue-500 block w-full sm:text-sm border"
                    required
                    v-model="company.city"
                  />
                </div>
                <div class="grid grid-cols-2 gap-4 my-2">
                  <div class="flex flex-col">
                    <label
                      for="businessZipCode"
                      class="text-primary-dark font-semibold text-sm"
                      style="margin-bottom: 0"
                    >
                      Zipcode
                    </label>
                    <input
                      type="text"
                      id="businessZipCode"
                      class="focus:border-blue-500 block w-full sm:text-sm border"
                      required
                      v-model="company.zip"
                    />
                  </div>

                  <div class="flex flex-col">
                    <label
                      for="businessState"
                      class="text-primary-dark font-semibold text-sm"
                      style="margin-bottom: 0"
                    >
                      State
                    </label>
                    <input
                      type="text"
                      id="businessState"
                      class="focus:border-blue-500 block w-full sm:text-sm border"
                      @change="altered = true"
                      required
                      v-model="company.state"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="text-black my-1">
            <input
              type="checkbox"
              class="cursor-pointer border"
              id="acceptTerms"
              v-model="acceptTerms"
            />
            <label for="acceptTerms"> I have read and agree to the </label
            ><router-link to="/privacy" target="_blank">terms of service</router-link>
          </div>

          <button
            type="submit"
            class="w-full btn-white text-2xl rounded-full md:rounded-lg my-5 h-16"
          >
            Sign Up
          </button>
        </form>

        <div class="mb-4 text-center">
          <span class="md:mr-4 mr-2">Already have an account?</span>
          <router-link to="/user" class="font-medium px-2 py-1">
            <p class="underline inline-block">Login</p>
            <span class="ml-2">></span>
          </router-link>
        </div>
      </div>

      <PopUp
        v-if="showTaxExemption"
        :title="'Tax Exemption Form'"
        @close="showTaxExemption = false"
      >
        <div class="text-primary md:hidden mt-4"><h3>Tax Exemption Form</h3></div>
        <ol class="px-2 md:p-0 text-left py-8">
          <li class="mb-2">
            <h4 class="font-normal">
              1. Download Tax exemption form
              <button @click="downloadFile" class="underline">here</button>
            </h4>
          </li>
          <li>
            <h4 class="font-normal">
              2. Email filled out form to
              <a href="mailto:accounting@pagecustomsupplyllc.com">
                accounting@pagecustomsupplyllc.com
              </a>
            </h4>
          </li>
        </ol>
      </PopUp>
    </div>
  </div>
</template>

<script>
import { ToggleButton } from 'vue-js-toggle-button';

import GoogleSignInButton from 'vue-google-signin-button-directive';
import FacebookLoginButton from 'vue-facebook-login-button-directive';
import PopUp from '@/components/shared/PopUp.vue';

export default {
  data: function () {
    return {
      registration: true,
      clientId: '501224162108-e7vb8p2n4up3qlq1ldgv1po5kjkatjp4.apps.googleusercontent.com',
      facebookId: '2735195263405373',

      toggleWidth: 60,
      color: '#005785',
      showTaxExemption: false,
      taxExempt: false,
      jobTitle: null,
      email: '',
      password: '',
      confirmPassword: '',
      phone: '',
      name: '',
      businessName: '',
      acceptTerms: false,
      company: {},
    };
  },
  computed: {
    user() {
      return this.$store.getters['user/getUser'];
    },
  },
  watch: {
    user() {
      if (this.user) {
        this.$router.push('/user');
      }
    },
  },
  mounted() {},
  methods: {
    downloadFile() {
      const fileURL = '/files/tax-exemption.pdf';
      const fileName = 'Tax Exemption Form PCS';
      // for non-IE
      if (!window.ActiveXObject) {
        var save = document.createElement('a');

        save.href = fileURL;
        save.target = '_blank';
        var filename = fileURL.substring(fileURL.lastIndexOf('/') + 1);
        save.download = fileName || filename;
        if (
          navigator.userAgent.toLowerCase().match(/(ipad|iphone|safari)/) &&
          navigator.userAgent.search('Chrome') < 0
        ) {
          document.location = save.href;
          // window event not working here
        } else {
          var evt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: false,
          });
          save.dispatchEvent(evt);
          (window.URL || window.webkitURL).revokeObjectURL(save.href);
        }
      }

      // for IE < 11
      else if (!!window.ActiveXObject && document.execCommand) {
        var _window = window.open(fileURL, '_blank');
        _window.document.close();
        _window.document.execCommand('SaveAs', true, fileName || fileURL);
        _window.close();
      }
    },
    OnGoogleAuthSuccess(token) {
      this.$store.dispatch('google', token);
      this.registration = false;
    },
    OnGoogleAuthFail() {
      this.$store.commit('setError', {
        message: 'Error logging in through Google.',
        color: 'red',
      });
    },
    OnFacebookAuthSuccess(token) {
      this.$store.dispatch('facebook', token);
      this.registration = false;
    },
    OnFacebookAuthFail() {
      this.$store.commit('setError', {
        message: 'Error logging in through Facebook.',
        color: 'red',
      });
    },
    changeTaxExempt() {
      this.taxExempt = !this.taxExempt;
    },
    taxExemptForm() {
      this.showTaxExemption = true;
    },
    register: async function () {
      if (this.confirmPassword !== this.password) {
        this.$store.commit('setError', {
          message: 'Passwords do not match',
          color: 'red',
        });
        return;
      }

      if (!this.acceptTerms) {
        this.$store.commit('setError', {
          message: 'Please read and agree to the terms of service before continuing.',
          color: 'red',
          duration: 6500,
        });
        return;
      }

      const user = {
        email: this.email,
        phone: this.phone,
        password: this.password,
        name: this.name,
        businessName: this.company.name,
        jobTitle: this.jobTitle,
        permissionLevel: 'Commercial',
        company: this.company,
      };

      user.company.taxExempt = this.taxExempt;

      this.$store.dispatch('register', user);
    },
  },
  directives: {
    GoogleSignInButton,
    FacebookLoginButton,
  },
  components: {
    ToggleButton,
    PopUp,
  },
};
</script>

<style scoped>
.facebook-login-button {
  color: white;
  background-color: #3b5998;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}

.google-login-button {
  color: black;
  background-color: white;
  /* box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
}
</style>
